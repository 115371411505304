import { Injectable } from '@angular/core';
import { Observable, throwError, from } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router'
import swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { ResponseService } from '../model/response.service';
import { InterviewResponseModel } from '../model/interviewResponseModel';

@Injectable({
  providedIn: 'root'
})
export class ConcursoService {
  constructor(private http: HttpClient) { }
  private httpHeaders = new HttpHeaders({ 'content-type': 'application/json' });

  getDataHome(): Observable<ResponseService> {
    return this.http.get<ResponseService>(
      environment._api + '/api/info/home/', { headers: this.httpHeaders }).pipe(
        catchError(e => {
          swal('Error get information', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }

  registro(page_id: string, label1_name: string, label2_phone: string, label3_code: string): Observable<ResponseService> {
    const params = {
      "page_id": page_id,
      "label1_name": label1_name,
      "label2_phone": label2_phone,
      "label3_code": label3_code
    };
    return this.http.post<ResponseService>(
      environment._api + '/api/registro/', params, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          swal('Error get information', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }

  getDataPage2(token: string, phone: string): Observable<ResponseService> {
    const params = {
      "token": token,
      "phone": phone
    };
    return this.http.post<ResponseService>(
      environment._api + '/api/info/pages/2/', params, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          swal('Error get information', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }

  registroConcurso(page_id:string,interviewResponse: InterviewResponseModel): Observable<ResponseService> {
    const params = {
      "page_id": page_id,
      "input_tags_1a": interviewResponse.page2.inputLabel1A,
      "input_tags_1b": interviewResponse.page2.inputLabel1B,
      "slider_value_1c": interviewResponse.page2.sliderLabel1C,
      "input_tags_2a": interviewResponse.page2.inputLabel2A,
      "input_tags_2b": interviewResponse.page2.inputLabel2B,
      "slider_value_2c_min": interviewResponse.page2.sliderLabel2C_min,
      "slider_value_2c_max": interviewResponse.page2.sliderLabel2C_max,
      "registro_id": interviewResponse.page2.registro_id
    };
    return this.http.post<ResponseService>(
      environment._api + '/api/registro/concurso/', params, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          swal('Error get information', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }

  getDataPage3(token: string, phone: string): Observable<ResponseService> {
    const params = {
      "token": token,
      "phone": phone
    };
    return this.http.post<ResponseService>(
      environment._api + '/api/info/pages/3/', params, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          swal('Error get information', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }

  sendCodeVerification(name: string, phone: string): Observable<ResponseService> {
    const params = {
      "label1_name": name,
      "phone": phone
    };
    return this.http.post<ResponseService>(
      environment._api + '/api/v/1.0/service/sns/concurso/sendCode', params, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          swal('Error get information', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }

  validateCode(phone: string, code: string): Observable<ResponseService> {
    const params = {
      "phone": phone,
      "code": code
    };
    return this.http.post<ResponseService>(
      environment._api + '/api/v/1.0/service/sns/concurso/validate', params, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          swal('Error get information', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }

}
