import { Component, OnInit, ViewChild } from '@angular/core';
import { Options } from 'ng5-slider';
import { InterviewModel } from '../model/interviewModel';
import { ConcursoService } from '../services/concurso.service'
import { InterviewResponseModel } from '../model/interviewResponseModel';
import swal from 'sweetalert2';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css']
})
export class InterviewComponent implements OnInit {
  constructor(private concursoService: ConcursoService) { }
  interview: InterviewModel = new InterviewModel();
  interviewResponse: InterviewResponseModel = new InterviewResponseModel();
  countryCode: string = "52";
  page_id: string;
  registro_id: number;
  token: string = "token";
  captchaResponse: string;

  optionsSlide1: Options = {
    floor: 0,
    ceil: 15000,
    step: 100,
    
    getPointerColor: (value: number): string => {
      return '#C3C3C3';
    },
    getSelectionBarColor: (value: number): string => {
      return '#015841';
    },
    showSelectionBar: true
  };
  optionsSlide2: Options = {
    floor: 0,
    ceil: 15000,
    step: 100,
    getPointerColor: (value: number): string => {
      return '#C3C3C3';
    },
    getSelectionBarColor: (value: number): string => {
      return '#015841';
    },
    showSelectionBar: true
  };

  @ViewChild('captchaRef') public captchaRef: RecaptchaComponent;

  ngOnInit() {
    const self = this;
    self.interviewResponse.page2.sliderLabel1C = 5800;
    self.interviewResponse.page2.sliderLabel2C_min = 3500;
    self.interviewResponse.page2.sliderLabel2C_max = 8100;
    self.concursoService.getDataHome().subscribe(
      responseService => {
        if (responseService["status"] == 'success') {
          self.page_id = responseService.data['id'];
          self.interview.title = responseService['title'];
          self.interview.page1.title = responseService.data['title'];
          self.interview.page1.introduction = responseService.data['title2'];
          self.interview.page1.input1 = responseService.data['inputLabel1'];
          self.interview.page1.placeholder1 = responseService.data['placeHolderInputLabel1'];
          self.interview.page1.input2 = responseService.data['inputLabel2'];
          self.interview.page1.placeholder2 = responseService.data['placeHolderInputLabel2'];
          self.interview.page1.recaptchaMsg = responseService.data['checkBoxLabel1'];
          self.interview.page1.button1Desc = responseService.data['buttonLabel1'];
          self.interview.page1.input3 = responseService.data['inputLabel3'];
          self.interview.page1.placeholder3 = responseService.data['placeHolderInputLabel3'];
          self.interview.page1.checkboxDesc = responseService.data['checkBoxLabel2'];
          self.interview.page1.button2Desc = responseService.data['buttonLabel2'];
          self.interview.page1.terms_conditions_title = "Términos y condiciones";
          self.interview.page1.terms_conditions = responseService.data['termsLabel'];
          self.interview.displayPage1 = true;
        } else {
          swal('Error', "Servicio en mantenimiento.", 'error');
        }
      }
    );
  }
  sendCodeVerification() {
    const self = this;
    if (self.inputsPage1(true)) {
      self.concursoService.sendCodeVerification(
        self.interviewResponse.page1.input1,
        self.countryCode + self.interviewResponse.page1.input2).subscribe(
          responseService => {
            swal('Código enviado', "Verifique su telefono.", 'success');
          }
        );
    }
  }

  validatePage1() {
    const self = this;

    if (self.inputsPage1(false)) {
      self.concursoService.validateCode(
        self.countryCode + self.interviewResponse.page1.input2,
        self.interviewResponse.page1.input3).subscribe(
          responseService => {
            if (responseService.data == true) {
              self.interviewResponse.page2.registro_id = responseService["registro_id"];
              self.concursoService.registro(
                self.page_id,
                self.interviewResponse.page1.input1,
                self.interviewResponse.page1.input2,
                self.interviewResponse.page1.input3).subscribe(
                  responseService2 => {
                    if (responseService2["status"] == 'success') {
                      self.concursoService.getDataPage2(self.token, self.interviewResponse.page1.input2).subscribe(
                        responseService3 => {
                          if (responseService3["status"] == 'success') {
                            self.page_id = responseService3.data['id'];
                            self.interview.page2.title = responseService3.data['title'];
                            self.interview.page2.label1 = responseService3.data['inputLabel1A'];
                            self.interview.page2.input1 = responseService3.data['placeHolderInputLabel1A'];
                            self.interview.page2.label2 = responseService3.data['inputLabel1B'];
                            self.interview.page2.input2 = responseService3.data['placeHolderInputLabel1B'];
                            self.interview.page2.slider1Desc = responseService3.data['sliderLabel1C'];
                            self.interview.page2.slider1Min = responseService3.data['sliderMin1C'];
                            self.interview.page2.slider1Max = responseService3.data['sliderMax1C'];
                            self.interview.page2.slider1Step = responseService3.data[''];
                            self.interview.page2.label3 = responseService3.data['inputLabel2A'];
                            self.interview.page2.input3 = responseService3.data['placeHolderInputLabel2A'];
                            self.interview.page2.label4 = responseService3.data['inputLabel2B'];
                            self.interview.page2.input4 = responseService3.data['placeHolderInputLabel2B'];
                            self.interview.page2.slider2Desc = responseService3.data['sliderLabel2C'];
                            self.interview.page2.slider2Min = responseService3.data['sliderMin2C'];
                            self.interview.page2.slider2Max = responseService3.data['sliderMax2C'];
                            self.interview.page2.slider2Step = responseService3.data[''];
                            self.interview.page2.button1Desc = responseService3.data['buttonLabel1'];

                            self.optionsSlide1.floor = self.interview.page2.slider1Min;
                            self.optionsSlide1.ceil = self.interview.page2.slider1Max;
                            self.optionsSlide2.floor = self.interview.page2.slider2Min;
                            self.optionsSlide2.ceil = self.interview.page2.slider2Max;

                            this.interview.displayPage1 = false;
                            this.interview.displayPage2 = true;
                            this.interview.displayPage3 = false;
                          } else {
                            swal('Error', "Servicio obtener datos.", 'error');
                          }
                        }
                      );
                    } else {
                      swal('Error', "Servicio en el registro.", 'error');
                    }
                  }
                );
            } else {
              swal('Validación de código', "Código invalido.", 'error');
            }
          }
        );
    }
  }
  backPage1() {
    this.interview.displayPage1 = true;
    this.interview.displayPage2 = false;
    this.interview.displayPage3 = false;
  }

  validatePage2() {
    const self = this;
    if (self.inputsPage2()) {
      self.concursoService.registroConcurso(self.page_id, self.interviewResponse).subscribe(
        responseService => {
          if (responseService["status"] == 'success') {
            self.interview.page3.amount = responseService.data['amount'];
            self.interview.page3.paymentCode = responseService.data['reference'];
            self.concursoService.getDataPage3(self.token, self.interviewResponse.page1.input2).subscribe(
              response => {
                self.interview.page3.title = response.data['title'];
                self.interview.page3.congratulation = response.data['textLabel1'];
                self.interview.page3.button1Desc = response.data['buttonLabel1'];
                this.interview.displayPage1 = false;
                this.interview.displayPage2 = false;
                this.interview.displayPage3 = true;
              }
            );
          } else {
            swal('Error', "Servicio en el registro.", 'error');
          }
        }
      );
    }
  }

  validatePage3() {
    this.redirectURL();
  }

  redirectURL() {
    this.interview.displayPage1 = false;
    this.interview.displayPage2 = false;
    this.interview.displayPage3 = false;
    window.location.replace(this.interview.redirectURL);
  }

  onValidateRecaptcha() {
    //console.log("this.interviewResponse.page1.checkBoxRecaptcha: " + this.interviewResponse.page1.checkBoxRecaptcha );
    //Ejecucion con recaptcha <re-captcha class="g-recaptcha" size="invisible" #captchaRef="reCaptcha" (resolved)="resolved($event)"></re-captcha>
    // if (this.interviewResponse.page1.checkBoxRecaptcha) {
    //   this.captchaRef.execute();
    // } else {
    //   this.captchaRef.reset();
    // }
  }

  public resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (captchaResponse != null) {
      this.interviewResponse.page1.checkBoxRecaptcha = true;
    } else {
      this.interviewResponse.page1.checkBoxRecaptcha = false;
    }
  }

  inputsPage1(sendConde: boolean): boolean {
    const self = this;
    let isValid = false;
    self.interview.page1Valid.input1 = true;
    self.interview.page1Valid.input2 = true;
    self.interview.page1Valid.checkBoxRecaptcha = true;

    if (self.interviewResponse.page1.input1 == '') {
      self.interview.page1Valid.input1 = false;
    }
    if (self.interviewResponse.page1.input2.length != 10) {
      self.interview.page1Valid.input2 = false;
    }
    if (self.interview.page1Valid.input1 && self.interview.page1Valid.input2
        && self.interviewResponse.page1.checkBoxRecaptcha == false) {
      self.interview.page1Valid.checkBoxRecaptcha = false;
    }

    if (self.interview.page1Valid.input1 
        && self.interview.page1Valid.input2 
        && self.interview.page1Valid.checkBoxRecaptcha) {
      isValid = true;
    }

    if (!sendConde) {
      isValid = false;
      self.interview.page1Valid.input3 = true;
      self.interview.page1Valid.terms_conditions = true;

      if (self.interviewResponse.page1.input3 == '')
        self.interview.page1Valid.input3 = false;
      if (self.interviewResponse.page1.terms_conditions == false)
        self.interview.page1Valid.terms_conditions = false;

      if (self.interview.page1Valid.input3 &&
        self.interview.page1Valid.terms_conditions) {
        isValid = true;
      }
    }

    return isValid;
  }

  inputsPage2(): boolean {
    const self = this;
    let isValid = false;
    self.interview.page2Valid.inputLabel1A = true;
    self.interview.page2Valid.inputLabel1B = true;
    self.interview.page2Valid.sliderLabel1C = true;
    self.interview.page2Valid.inputLabel2A = true;
    self.interview.page2Valid.inputLabel2B = true;
    self.interview.page2Valid.sliderLabel2C_min = true;
    self.interview.page2Valid.sliderLabel2C_max = true;

    if (self.interviewResponse.page2.inputLabel1A.length == 0)
      self.interview.page2Valid.inputLabel1A = false;
    if (self.interviewResponse.page2.inputLabel1B.length == 0)
      self.interview.page2Valid.inputLabel1B = false;

    if (self.interviewResponse.page2.inputLabel2A.length == 0)
      self.interview.page2Valid.inputLabel2A = false;
    if (self.interviewResponse.page2.inputLabel2B.length == 0)
      self.interview.page2Valid.inputLabel2B = false;


    if (self.interview.page2Valid.inputLabel1A &&
      self.interview.page2Valid.inputLabel1B &&
      self.interview.page2Valid.sliderLabel1C &&
      self.interview.page2Valid.inputLabel2A &&
      self.interview.page2Valid.inputLabel2B &&
      self.interview.page2Valid.sliderLabel2C_min &&
      self.interview.page2Valid.sliderLabel2C_max) {
      isValid = true;
    }

    return isValid;
  }

}
