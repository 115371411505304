export class InterviewModel {
  displayPage1: boolean = false;
  displayPage2: boolean = false;
  displayPage3: boolean = false;
  title: string;
  page1: {
    title: string;
    introduction: string;
    input1: string;
    placeholder1: string;
    input2: string;
    placeholder2: string;
    recaptchaMsg: string;
    button1Desc: string;
    input3: string;
    placeholder3: string;
    checkboxDesc: string;
    button2Desc: string;
    terms_conditions_title: string;
    terms_conditions: string;
  };
  page2: {
    title: string;
    label1: string;
    input1: string;
    label2: string;
    input2: string;
    slider1Desc: string;
    slider1Min: number;
    slider1Max: number;
    slider1Step: number;
    label3: string;
    input3: string;
    label4: string;
    input4: string;
    slider2Desc: string;
    slider2Min: number;
    slider2Max: number;
    slider2Step: number;
    button1Desc: string;
  };
  page3: {
    title: string;
    congratulation: string;
    paymentCode: string;
    amount: string;
    button1Desc: string;
  };
  redirectURL: string;
  company: {
    name: string;
  };

  page1Valid: {
    input1: boolean;
    input2: boolean;
    checkBoxRecaptcha: boolean;
    input3: boolean;
    terms_conditions: boolean;
  }
  page2Valid: {
    inputLabel1A: boolean;
    inputLabel1B: boolean;
    sliderLabel1C: boolean;
    inputLabel2A: boolean;
    inputLabel2B: boolean;
    sliderLabel2C_min: boolean;
    sliderLabel2C_max: boolean;
  }

  constructor() {
    this.title = "Main Title";
    this.page1 = {
      title: "Title first page",
      introduction: "Lorem ipsum dolor sit amet consectetur adipiscing elit hendrerit ultricies donec tempor, euismod arcu class cum nulla penatibus platea sagittis ultrices lacinia, tincidunt nisi lectus aenean in dignissim porttitor tortor porta diam.",
      input1: "Name",
      placeholder1:"",
      input2: "Phone Number",
      placeholder2:"",
      recaptchaMsg: "I'm not a robot",
      button1Desc: "Send code",
      input3: "Access Code",
      placeholder3:"",
      checkboxDesc: "I accept terms and conditions",
      button2Desc: "Accept",
      terms_conditions_title: "Terms and conditions",
      terms_conditions: "Lorem ipsum dolor sit amet consectetur adipiscing elit hendrerit ultricies donec tempor, euismod arcu class cum nulla penatibus platea sagittis ultrices lacinia, tincidunt nisi lectus aenean in dignissim porttitor tortor porta diam. Nunc nibh feugiat non class molestie mattis ultricies curabitur, nascetur tempus ante commodo lobortis platea semper tortor, iaculis elementum convallis natoque porta proin suspendisse. Urna condimentum semper aenean laoreet quis convallis vivamus dis montes tellus, per ante leo pellentesque sociosqu ornare nisi libero vestibulum, est vulputate velit proin tincidunt ullamcorper augue torquent lacinia. Ac senectus proin dis semper pellentesque eros, ultrices turpis venenatis faucibus inceptos. Egestas ut accumsan erat nascetur per platea vitae donec, sem class maecenas morbi venenatis proin congue massa, etiam posuere tristique volutpat metus orci nibh. Fusce natoque tincidunt tristique eu congue ante lacinia accumsan ultrices velit, vestibulum suspendisse turpis dis nascetur blandit vivamus fames est, varius sociosqu eros quam cursus cum sodales class ultricies."
    };
    this.page2 = {
      title: "Title second page",
      label1: "First question?",
      input1: "First question?",
      label2: "Second question?",
      input2: "Second question?",
      slider1Desc: "Slide bar 1",
      slider1Min: 0,
      slider1Max: 15000,
      slider1Step: 100,
      label3: "Third question?",
      input3: "Third question?",
      label4: "Fourth question?",
      input4: "Fourth question?",
      slider2Desc: "Slide bar 2",
      slider2Min: 0,
      slider2Max: 15000,
      slider2Step: 100,
      button1Desc: "Accept",
    };
    this.page3 = {
      title: "Title third page",
      congratulation: "Lorem ipsum dolor sit amet consectetur adipiscing elit hendrerit ultricies donec tempor, euismod arcu class cum nulla penatibus platea sagittis ultrices lacinia, tincidunt nisi lectus aenean in dignissim porttitor tortor porta diam. Nunc nibh feugiat non class molestie mattis ultricies curabitur, nascetur tempus ante commodo lobortis platea semper tortor, iaculis elementum convallis natoque porta proin suspendisse. Urna condimentum semper aenean laoreet quis convallis vivamus dis montes tellus, per ante leo pellentesque sociosqu ornare nisi libero vestibulum, est vulputate velit proin tincidunt ullamcorper augue torquent lacinia. Ac senectus proin dis semper pellentesque eros, ultrices turpis venenatis faucibus inceptos. Egestas ut accumsan erat nascetur per platea vitae donec, sem class maecenas morbi venenatis proin congue massa, etiam posuere tristique volutpat metus orci nibh.",
      paymentCode: "LOREM IPSUM",
      button1Desc: "Complete",
      amount:''
    };
    this.redirectURL = "https://frenteurbano.org/";
    this.company = {
      name: "Frente"
    };

    this.page1Valid= {
      input1: true,
      input2: true,
      checkBoxRecaptcha: true,
      input3: true,
      terms_conditions: true
    }
    this.page2Valid= {
      inputLabel1A: true,
      inputLabel1B: true,
      sliderLabel1C: true,
      inputLabel2A: true,
      inputLabel2B: true,
      sliderLabel2C_min: true,
      sliderLabel2C_max: true
    }
  }
}