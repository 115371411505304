export class InterviewResponseModel {
  page1: {
    input1: string;
    input2: string;
    checkBoxRecaptcha: boolean;
    input3: string;
    terms_conditions: boolean;
  }
  page2: {
    inputLabel1A: any;
    inputLabel1B: any;
    sliderLabel1C: number;
    inputLabel2A: any;
    inputLabel2B: any;
    sliderLabel2C_min: number;
    sliderLabel2C_max: number;
    registro_id: number;
  }

  constructor() {
    this.page1 = {
      input1: "",
      input2: "",
      checkBoxRecaptcha: false,
      input3: "",
      terms_conditions: false
    };
    this.page2 = {
      inputLabel1A: [],
      inputLabel1B: [],
      sliderLabel1C: 0,
      inputLabel2A: [],
      inputLabel2B: [],
      sliderLabel2C_min: 0,
      sliderLabel2C_max: 0,
      registro_id: 0,
    };

  }
}